@import url('https://fonts.googleapis.com/css2?family=Metal+Mania&display=swap');

.home{
    width: 100%;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: transparent;
    justify-content: center;
}
.logoText{
    font-family: 'Metal Mania';
    font-size: 200px;
    /* width: 40%; */
    letter-spacing: 20px;
    gap:10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.sympoDesc{
    font-size: 20px;
}
.logo {
    width: 181px;
    height: 196px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%; /* Circular shape */
    overflow: hidden;
    position: relative;
    /* background: black; Optional: fallback background */
  }
  
  .logo video {
    width: 100%; /* Ensures video fully fills the width */
    height: 100%; /* Ensures video fully fills the height */
    object-fit: cover; /* Maintain aspect ratio while filling the container */
    position: absolute; /* Ensures it stays inside the container */
     /* Align to top */
    left: 0; /* Align to left */
  }
@media screen and (max-width:720px) {
    .logoText{
        font-size: 100px;
    }
    .logo{
        height:99px;
        width: 92px;

    }
}
@media screen and (max-width:480px) {
    .logoText{
        font-size: 50px;
    }
    .logo{
        height:52px;
        width: 49px;
        margin-left: -10px;

    }
    .sympoDesc{
        font-size: 16px;
    }
}


/* $prime: #FF6E48;
$second: #1b2431; */

@font-face {
  font-family: "Liberty";
  src: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/907368/liberty.otf');
}
/* 
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #1b2431;
  font-size: 10px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
} */

.nexus {
  display: flex;
  flex-flow: row;
  font-family: 'Liberty';
  font-weight: 100;
  font-size: 7rem;
  letter-spacing: -0.25em;
  gap: 12px;
  margin-top: 0%;

  .letter {
    color: #d9fdff;
    text-shadow: 0 0 2rem #00f0ff;
    display: inline-block;

    &.letter-2 {
      transform: translate(-0.2rem, 1rem) rotate(10deg);
      animation: flicker 2s ease-in-out infinite alternate;
    }
  }
}

@keyframes flicker {
  0%, 5%, 6%, 8%, 50%, 100% {
    opacity: 1;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
  5.5%, 7% {
    opacity: 0;
    transform: translate(-0.2rem, 1rem) rotate(10deg);
  }
}

@media screen and (max-width: 768px) {
    .nexus {
      font-size: 4rem; /* Reduce font size for smaller screens */
      gap: 8px; /* Adjust spacing */
      margin-top: 2%; /* Adjust top margin */
  
      .letter {
        text-shadow: 0 0 1rem #00f0ff; /* Reduce shadow intensity */
      }
    }
  }