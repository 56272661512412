@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900&display=swap');

/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}  */
.titleMain{
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}
.sponcers{
  background: black !important;
  min-height: 100vh;
  overflow-x: hidden;
  padding-top:50px ;
  font-family: 'Poppins', sans-serif;
} 

.titleSponcer{
  margin:30px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.titleSponcer h1 {
  font-size: 50px;
  font-weight: 600;
  color: transparent;
  position: relative;
  -webkit-text-stroke: 2px #007BFF;
  color: #024ff4;
}

/* .titleSponcer h1::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  color: #024ff4;
  overflow: hidden;
  border-right: 3px solid #007BFF;
  border-radius: 4px;
  animation: animate 8s linear infinite; 
}*/

@keyframes animate {
  0%,10%,100%{
    width:0%;
  }
  70%,90%{
    width:100%;
  }
  
}

.title_1{
  border-radius: 10px;
  position: relative;
  z-index:50;
  border: 2px solid #007BFF;
  overflow: hidden;
  display:flex !important;
  flex-direction: row; 
  padding: 10px;
  width: 90%;
  background:rgb(20, 20, 20);
}


/* .title_1::before{
  content: "";
  position: absolute;
  border-radius: 10px;
  top:15% !important;
  left:-50% !important;
  inset: 5px;
  width: 200%;
  height:300px ;
  background:#FF073A;
  animation:rotating 4s linear infinite;
}

.title_1::after{
  content: "";
  position: absolute;
  background:rgb(20, 20, 20);
  inset: 5px;
} */
.title_1>div{
  width: 50%;
}
@keyframes rotating{
  from{
    transform: rotate(0deg);
  }to{
    transform: rotate(360deg);
  }
  
}


.content{
  z-index: 50;
  display: flex;
  flex-direction: column;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  padding: 25px !important;
}
.content h2{
  text-align: center;
  padding: 15px 0;
  font-weight: 600;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.675);
}
.sponcers p{
  text-align:left;
  font-size: 18px;
  line-height: 20px;
  word-spacing: 5px;
  letter-spacing: 3px;
  color:white;
  padding: 20px 0;
  padding-left: 20px;
  border-left: 2.5px solid white;
  border-radius: 5px;
}
.image{
  z-index: 50;
  width: 100%;
  padding: 30px 20px;
}
.sponcers a{
  border: 2px solid white;
  text-decoration: none;
  color: white;
  padding: 5px 15px;
  width: fit-content;
  border-radius: 5px;
  margin: 10px 0;
  background-color: transparent;
  font-size: 1rem;
}

.sponcers a:hover{
  box-shadow: inset 200px 0 0 0 white;
  color: black;
  transition: 1s;
  font-weight: 600;
}


.cosponcers{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 50px ;
}

.cosponcers img {
  height: 400px;
  z-index: 50;
  margin:30px !important;
  width: 50%;
  height: 50%;
  border-radius: 10px !important; 
}



.cosponcers>div{
  border: 2px solid #007BFF;
  border-radius: 10px;
  width: 570px;
  margin: 30px;
  padding-top: 30px;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 50;
  overflow: hidden !important; 
  background:rgb(20, 20, 20);
}


.malaifi1 img {
  margin-top:30px !important;
  width: 70% !important;
  height: 70% !important;
   
}
.malaifi1>div{
  min-height: 520px !important;
  width:500px;
}

.malaifi1 h2{
  font-size: 40px !important;
  padding:0 !important;
}

/* .cosponcers>div::before{
  content: "";
  position: absolute;
  border-radius: 10px;
  top:15% !important;
  left: -50% !important;
  inset: 5px;
  width: 200%;
  height:300px ;
  background:#FF073A;
  /* z-index: 1000; 
  animation:rotating 4s linear infinite;
} 
.cosponcers>div::after{
  content: "";
  position: absolute;
  background:rgb(20, 20, 20);
  inset: 5px;
  cursor: pointer;
}*/
/* .tastytreats{
  padding: 50px 0;
}
.greentrends{
  padding: 20px 0;
} */
.sponcers h2{
  font-size: 25px;
}



@media (max-width:768px) {

  .cosponcers>div{
    padding:0;
  }
  .title_1>div{
    width: 100%;
  }
  .cosponcers img{
    width: 80%;
    height: 80%;
    padding:0;
  }
  

  /* .cosponcers>div::before{
    top:20% !important;
    width: 200%;

  } */
  .cosponcers>div{
    width: 90%;
  }
  .title_1{
    padding: 0px;
    flex-direction: column;
    width: 90%;
  }
  .cosponcers{
    flex-direction: column;
  }
  /* .title_1::before{
    width: 200%;
    top:25% !important;
    left:-50% !important;
  } */
  .sponcers p{
    font-size: 13px;
  }
}

@media (max-width:400px) {

  /* .cosponcers>div::before{
    top:-25% !important;
    width: 50%;
    height: 150%;
    left: 15% !important;
  } */
  /* .title_1::before{
    width: 50%;
    height: 150%;
    top:-18% !important;
    left:18% !important;
  } */
  /* .cosponcers .antway img{
    width:70%;
    height: 50%;
  } */

  .title_1{
    margin: 20px !important;
    width: 90%;
    padding:0;
    flex-direction: column;
  }
  .coSponcer{
    font-size: 10px !important;
  }
  .sponcers p{
    font-size: 12px;
    font-weight: normal;
  }
  .titleSponcer h1 {
    font-size: 35px;
    font-weight: 600;
    /* color: transparent;
    position: relative; */
  }
  /* .cosponcers img{
    width: 60%;
    height: 60%;
    padding:0;
  } */
}
