@import url('https://fonts.googleapis.com/css2?family=Lisu+Bosa:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.App {
  text-align: center;
  position: relative;
  z-index: 0;
  font-family: 'Lisu';
/*   
  background:linear-gradient(135deg, #1e1e2f, #121212);
  backdrop-filter: blur(2px); */
  background-color: black;
  color: white;
  /* overflow-x: hidden; */
  scroll-behavior: smooth;
}
