/* General Container Styling */
body {
  background: linear-gradient(135deg, #101010, #0f0f0f); /* Light gradient for the whole site */
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;

}
.container {
  width:90%;
  height: 500%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  margin-left: 80px;
  padding-top:3%;
  padding-bottom: 5%;

  
}

.carousel-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;

}

.topic-heading {
  font-size: 2em; /* Adjust size as needed */
  font-weight: bold;
  color: #fff; /* Base text color */
  text-shadow: 
      0 0 5px #fff, 
      0 0 10px #fff, 
      0 0 20px #dddddd,
      0 0 30px #080708,
      0 0 40px #212021,
      0 0 50px #080008,
      0 0 60px #fffeff;
  
}


/* Carousel Component Styling */


/* Card Styling */
.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
 

  color: #ffffff; /* Text Color */
  
  width: 16rem;
  padding: 0 1.5rem 1.5rem 1.5rem;
  height: auto;

  border-radius: 5px;
  transition: width 0.3s ease-in-out;

}




.card:hover  {
  transform: translateY(-3px); /* Slight lift effect on hover */
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */

  border-radius: 7px;
}


.card .event-details {
  display: flex; /* Use flexbox for vertical stacking */
  flex-direction: column; /* Stack items vertically */
  align-items: flex-start; /* Align items to the left */
  gap: 1rem; /* Space between each detail */
  width: 100%; /* Ensure it spans the width of the card */
  margin-bottom: 1rem; /* Add spacing below the details */
}

.card .event-details p {
  margin: 0; /* Remove extra margins */
  text-align: left; /* Align text to the left */
  font-size: 0.9rem; /* Adjust font size for better readability */
  line-height: 1.2; /* Consistent spacing between lines */
}


.card-image {
  margin-top: -10%;
  width: 100%; /* Slightly reduce image width for better fit */
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
  border-radius: 5px;
  
}

.card h2 {
  margin: 0;
  text-align: center;
  font-size: 120%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 12px;
  text-shadow: 
  0 0 5px #fff, 
  0 0 10px #ebe9eb, 
  0 0 20px #161516, 
  0 0 30px #2aacac; /* Adjust glow intensity */
}

.card p {
  margin: 0;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.card .btnn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  margin-left: 10px;
  margin-top: -2px;
  
}


/* Responsive Design */

/* For Tablets and Small Screens (max-width: 1024px) */
@media (max-width: 1024px) {
  .container{
    margin-left: 0px;
  
  }
  .arrow-btn{
    bottom:-10px;
   }
  .carousel-container > div {
    height: 270px;
    width:80%;
  }

  .topic-heading {
    font-size: 1.9rem;
  }

  .card {
    width: 18rem;
    padding: 0 1.5rem 1.5rem 1.5rem;
  }

  .card img {
    margin-top: -10%;
    width: 100%; /* Slightly reduce image width for better fit */
    max-width: 100%;
  }
  .card:hover  {
    transform: translateY(-3px); /* Slight lift effect on hover */
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3); /* Enhance shadow on hover */
  
    border-radius: 7px;
  }

  .card h2 {
    margin-left: 15px;
  }

  .card .btnn {
    gap: 15px;
    margin-left: 10px;
  }
}

/* For Mobile Devices (max-width: 768px) */
@media (max-width: 768px) {
  .container{
    margin-left: 0px;
  }
  .carousel-container > div {
    height: 320px;
  }
  .arrow-btn{
    bottom:-10px;
   }
  .topic-heading {
    font-size: 2.1rem;
    align-self: center;
  }

  .carousel-container {
    margin: 15px auto;
  }

  .card {
    width: 16rem;
    padding: 0 1.2rem 1.2rem 1.2rem;
  }

  .card img {
    margin-top: -15%;
    width: 100%; /* Maintain more size */
    max-width: 100%; /* Prevent image from shrinking too much */
  }

  .card h2 {
    margin-left: 12px;
  }

  .card .btnn {
    gap: 10px;
    margin-left: 8px;
  }
}

/* For Very Small Devices (max-width: 480px) */
@media (max-width: 480px) {
  .carousel-container > div  {
    height: 200px;
    width:100%;

  
  }
 .arrow-btn{
  bottom:-20px;
  padding-bottom: 2%;
 }
  .container{
    max-height: auto;
    margin-left: 0px;
    overflow: hidden;
    width: 100%;
    padding-bottom: 5rem;
  
    
  }

  .topic-heading {
    font-size: 1.9rem;
    margin-top: -20px;
  }

  .card {
    width: 16rem;
    margin-top: 40px;
    padding: 0 1rem 1rem 1rem;
  }

  .card img {
    margin-top: -12%;
    width: 100%; /* Allow a bit more space on very small screens */
    max-width: 100%; /* Maintain more size */
  }

  .card h2 {
    margin-left: 10px;
  }
  .arrow-btn{
    bottom: -40px;
    height: auto;
    width: auto;

  }

  .card .btnn {
    gap: 8px;
    margin-left: 5px;
  }
}