.events{
    
    height: fit-content;
    padding: 2rem;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;

}

.events-container{
    width: 70%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap:30px;
    
}
.events-card{
    background: rgb(143, 40, 40);
    width: 325px;
    height: 400px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.events-card-img{
    width: 325px;
    height: 294px;
    background: whitesmoke;
    margin-left: -10px;
    margin-top: -10px;
    object-fit: cover;
}
.events-card-title{
    color: red;

}
@media screen and (max-width:480px) {
    .events{
        padding: 0.2em;
    }
}