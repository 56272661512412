* {
    box-sizing: border-box;
}



.center {
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

p {

    font-size: 16px;
    display: block;
    text-align: center;
   
    letter-spacing: 1px;
    line-height: 30px;
}

.char {
    font-size: 16px;
    
    animation: an 1s ease-out 1 both;
    display: inline-block;
}

@keyframes an {
    from {
        opacity: 0;
        transform: perspective(500px) translate3d(-35px, -40px, -150px) rotate3d(1, -1, 0, 35deg);
    }

    to {
        opacity: 1;
        transform: perspective(500px) translate3d(0, 0, 0);
    }
}